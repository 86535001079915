var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "orderFlowModal",
      attrs: {
        title: _vm.editTitle,
        "mask-closable": false,
        draggable: false,
        width: "750",
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c("Alert", { staticClass: "ivu-alert-left" }, [_vm._v(" 审批流程 ")]),
      _c(
        "Row",
        [
          _c(
            "Steps",
            { attrs: { current: _vm.current - 1 } },
            [
              _c("Step", { attrs: { title: "提交审核" } }),
              _c("Step", { attrs: { title: "主检医生审核" } }),
              _c("Step", { attrs: { title: "体检中心主任审核" } }),
              _c("Step", { attrs: { title: "技术负责人审核" } }),
              _c("Step", { attrs: { title: "完成" } }),
            ],
            1
          ),
        ],
        1
      ),
      _c("Alert", { staticClass: "ivu-alert-left" }, [_vm._v(" 审批节点 ")]),
      _c(
        "Timeline",
        { staticClass: "rightTimeLine" },
        _vm._l(_vm.flowData, function (item, index) {
          return _c(
            "TimelineItem",
            {
              key: item.id,
              attrs: {
                color:
                  item.auditState == 1
                    ? "green"
                    : item.auditState == 4
                    ? "red"
                    : "#808695",
              },
            },
            [
              _c(
                "Row",
                [
                  _c(
                    "Col",
                    {
                      staticStyle: { padding: "3px 3px 3px 0" },
                      attrs: { span: "24" },
                    },
                    [_vm._v("审批人：" + _vm._s(item.auditUserName))]
                  ),
                  _c(
                    "Col",
                    {
                      staticStyle: { padding: "3px 3px 3px 0" },
                      attrs: { span: "24" },
                    },
                    [_vm._v("审核时间：" + _vm._s(item.auditTime))]
                  ),
                  _c(
                    "Col",
                    {
                      staticStyle: {
                        padding: "3px 3px 3px 0",
                        background: "#ccc",
                        color: "#0000FF",
                      },
                      attrs: { span: "24" },
                    },
                    [_vm._v(" 意见：" + _vm._s(item.auditContent) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }